<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'compilations-index' }" />

    <template v-if="compilation">
      <h2 class="mb-4">Редактирование подборки</h2>

      <v-sheet elevation="3">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>

          <v-tab-item>
            <div class="pa-4">
              <compilation-form
                :compilation="compilation"
                :loading="updating"
                @change-field="changeField"
                @submit="updateCompilation"
              />
            </div>
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Подборка с id "{{ id }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import { UpdateCompilationDto } from "~/libs/compilation/update-compilation-dto";
import CompilationForm from "~/components/compilations/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      compilation: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchCompilation();
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchCompilation() {
      this.loading = true;

      try {
        const compilation = await this.$axios.$get(`compilations/${this.id}`);

        this.compilation = UpdateCompilationDto.from(compilation);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Подборка с id: "${this.id}" не найдена ${html}`);
      } finally {
        this.loading = false;
      }
    },

    async updateCompilation() {
      if (this.updating) return;

      this.updating = true;

      try {
        await this.$axios.$patch(`compilations/${this.id}`, UpdateCompilationDto.create(this.compilation));
        await this.fetchCompilation();

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.compilation)).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    changeField(payload) {
      this.commitChange(this.compilation, payload);
    }
  },
  components: {
    CompilationForm
  }
};
</script>
